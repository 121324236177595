import { Link } from 'react-router-dom'
import UserIcon from '../assets/profile-user.svg'
import LogoIcon from '../assets/nEureka_Logo_main.png'
import PasswordIcon from '../assets/password.svg'
import PasswordEyeIcon from '../assets/eye.svg'
import GoogleIcon from '../assets/google.svg'
const Login = ()=>{
    return(
        <div className="container-fluid full-height">
            <div className="row h-100">
                {/* form */}
                <div className="col p-0 d-flex flex-column align-items-center my-auto">
                    <div className='p-5'>
                        <img src={LogoIcon} alt="User Icon" width="270" height="55" className='' />
                    </div>
                    <form className=''>
                        {/* userName */}
                        <div className="input-group mb-4">
                            <span className="input-group-text bg-white" id="basic-addon1">
                                <img src={UserIcon} alt="User Icon" width="24" height="24" className='mx-2' />
                            </span>
                            <input type="text" className="form-control p-3" placeholder="Username or email" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        {/* password */}
                        <div className="input-group mb-4">
                            <span className="input-group-text bg-white" id="basic-addon1">
                                <img src={PasswordIcon} alt="User Icon" width="24" height="24" className='mx-2' />
                            </span>
                            <input type="password" className="form-control p-3 border-end-0" placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                            <span className="input-group-text bg-white">
                                <img src={PasswordEyeIcon} alt="User Icon" width="24" height="24" className='mx-2' />
                            </span>
                        </div>
                        {/* submit */}
                        <div className='d-flex align-items-center justify-content-between mb-4'>
                            <div className=" form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label text-secondary" for="exampleCheck1">Remember me</label>
                            </div>
                            <button type="submit" className="btn btn-primary px-4 py-3">LOGIN</button>
                        </div>
                        {/* register */}
                        <div className='mb-5 d-flex align-items-center justify-content-between'>
                            <div>
                                <Link to="/clinic-registration" className='decorations-none'> Register now</Link>
                            </div>
                            <div>
                                <a href='#' className='decorations-none text-secondary'> Forgot password?</a>
                            </div>
                        </div>
                        {/* divider */}
                        <div className='d-flex align-items-center mb-4'>
                            <hr className='w-100'/>
                            <p className='px-5 m-0 text-secondary'>Or</p>
                            <hr className='w-100' />
                        </div>
                        {/* sign in with google */}
                        <div className="input-group mb-4 px-md-5">
                            <span className="input-group-text bg-white border-primary " id="basic-addon1">
                                <img src={GoogleIcon} alt="Google Icon" width="24" height="24" className='mx-2' />
                            </span>
                            <input type="submit" className="form-control p-3 btn btn-primary" value='Sign in with Google'  aria-label="signInWithGoogle" aria-describedby="basic-addon1" />
                        </div>
                    </form>
                </div>
                {/* image */}
                <div className="col p-0 d-md-block d-none">
                    {/* <img src="" alt="" className="" /> */}
                    <div className='login-image h-100'></div>
                </div>
            </div>
        </div>
    )
}
export default Login