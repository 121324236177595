import { useEffect } from "react"
const Register = ()=>{
    useEffect(()=>{
        window.location.href = 'https://hipaa.jotform.com/223245418822252'
    },[])
    return (
        <>
        </>
    )
}
export default Register