import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login'
import Register from './Register'
import './App.css';

function App() {
  return (
    <div className="App">
      
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/clinic-registration" element={<Register />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
